import type { MinimaticData } from "@/signomatic/app/minimatic"

export interface AdminState {
  id: number
  developer: boolean
  lastOrderId: number
}

export interface EditingState {
  id: number
  type: string
  admin: boolean
  fixedPrice: boolean
  typeUpdate: boolean
  orderId: number | undefined
}

export interface SharingData {
  id: string
  revision?: number
  active?: boolean
}

interface FontVariant {
  path: string
  filename: string
  basename: string
  type: "ttf" | "woff" | "woff2"
}

interface FontVariants {
  [index: string]: FontVariant | undefined
  normal: FontVariant
  bold?: FontVariant
  italic?: FontVariant
  bold_italic?: FontVariant
}

export interface Fontmap {
  [index: string]: FontVariants
}

export interface Fontdata {
  filemap: Fontmap
  disabled: string[]
  sets: {
    [index: string]: string[]
    all: string[]
    custom: string[]
    cutter: string[]
    limited: string[]
  }
}

interface ClientDataPreload {
  readonly order_id?: number
  readonly admin?: AdminState

  // admin pages
  readonly order_digital_customs_declaration?: boolean

  // signomatic
  readonly root_path?: string
  readonly start_path?: string
  readonly signs?: SignType[]
  readonly sign_data?: SignData[]
  readonly skip_toolbox?: boolean
  readonly editing?: EditingState
  readonly sharing_data?: SharingData
  readonly load_sign_type?: any
  readonly load_sign?: any
  readonly load_sign_convert?: any
  readonly example_id?: number
  readonly recommended?: string
  readonly sign_category_id?: number
  readonly exampler_id?: number
  readonly wood_engraved?: boolean
  readonly wood_type_alert?: boolean
  readonly wood_size_alert?: boolean
  readonly fontdata?: Fontdata
  readonly signs_path?: string
  readonly allowed_image_types?: string
  readonly toolbox_snippets?: {
    [index: string]: string
  }
  readonly meta_products?: string[]
  readonly minimatic_sign?: boolean
  readonly minimatic_data?: MinimaticData
  readonly sign_count?: number

  // checkout
  readonly hide_header?: boolean
  readonly hide_update_buttons?: boolean
  readonly vat_enabled_countries?: string[]
  readonly delivery_states?: string[]
  readonly delivery_states_au?: string[][]
  readonly delivery_countries?: string[][]
  readonly snippets?: {
    [index: string]: string | undefined
    customer?: string
  }
  readonly default_country?: string
  readonly guest_last_order_id?: string
  readonly shipping_and_payment_path?: string

  // application
  readonly recaptcha_key?: string
  readonly client_role?: "business" | "consumer"
}

declare global {
  interface Window {
    clientData: ClientDataPreload
  }
}

const ClientData = {
  update(data: Record<string, unknown>) {
    if (typeof window === "undefined") {
      return
    }

    window.clientData = window.clientData || {}
    window.clientData = { ...window.clientData, ...data }
  },

  get data() {
    return typeof window !== "undefined" ? window.clientData : {}
  },

  set data(data: ClientDataPreload) {
    if (typeof window === "undefined") {
      throw new Error("Cannot set client data on server")
    }

    window.clientData = data
  },

  get orderId() {
    return ClientData.data.order_id
  },

  get orderDigitalCustomsDeclaration() {
    return ClientData.data.order_digital_customs_declaration
  },

  get admin() {
    return ClientData.data.admin
  },

  get developer() {
    return ClientData.admin && ClientData.admin.developer
  },

  get skipToolbox() {
    return ClientData.data.skip_toolbox
  },

  get editing() {
    return ClientData.data.editing
  },

  get loadSign() {
    return ClientData.data.load_sign
  },

  get loadSignConvert() {
    return ClientData.data.load_sign_convert
  },

  get loadSignType() {
    return ClientData.data.load_sign_type
  },

  get exampleID() {
    return ClientData.data.example_id
  },

  get signCategoryID() {
    return ClientData.data.sign_category_id
  },

  get examplerID() {
    return ClientData.data.exampler_id
  },

  get minimaticSign() {
    return ClientData.data.minimatic_sign
  },

  get minimaticData() {
    return ClientData.data.minimatic_data
  },

  get signCount() {
    return ClientData.data.sign_count
  },

  get woodEngraved() {
    return ClientData.data.wood_engraved
  },

  get woodTypeAlert() {
    return ClientData.data.wood_type_alert
  },

  get woodSizeAlert() {
    return ClientData.data.wood_size_alert
  },

  get sharingData() {
    return ClientData.data.sharing_data
  },

  get rootPath() {
    return ClientData.data.root_path
  },

  get startPath() {
    return ClientData.data.start_path
  },

  get signs() {
    return ClientData.data.signs
  },

  get signData() {
    return ClientData.data.sign_data
  },

  get fontdata() {
    return ClientData.data.fontdata
  },

  get signsPath() {
    return ClientData.data.signs_path
  },

  get shippingAndPaymentPath() {
    return ClientData.data.shipping_and_payment_path
  },

  get allowedImageTypes() {
    return ClientData.data.allowed_image_types
  },

  get hideHeader() {
    return ClientData.data.hide_header
  },

  get hideUpdateButtons() {
    return ClientData.data.hide_update_buttons
  },

  get vatEnabledCountries() {
    return ClientData.data.vat_enabled_countries
  },

  get deliveryStates() {
    return ClientData.data.delivery_states
  },

  get deliveryStatesAU() {
    return ClientData.data.delivery_states_au
  },

  get deliveryCountries() {
    return ClientData.data.delivery_countries
  },

  get defaultCountry() {
    return ClientData.data.default_country
  },

  snippet(name: string) {
    if (!ClientData.data.snippets) {
      return ""
    }

    return ClientData.data.snippets[name] || ""
  },

  get guestLastOrderId() {
    return ClientData.data.guest_last_order_id
  },

  toolboxSnippet(name: string) {
    if (!ClientData.data.toolbox_snippets) {
      return ""
    }

    return ClientData.data.toolbox_snippets[name + "_info"] || ""
  },

  get metaProducts() {
    return ClientData.data.meta_products
  },

  get recaptchaKey() {
    return ClientData.data.recaptcha_key
  },

  get consumerRole() {
    return ClientData.data.client_role === "consumer"
  },

  get businessRole() {
    return ClientData.data.client_role === "business"
  },

  get recommended() {
    return ClientData.data.recommended
  },
}

if (typeof window !== "undefined" && !window.clientData) {
  const dataTag = document.getElementById("sm-clientdata")

  if (dataTag) {
    ClientData.data = JSON.parse(dataTag.textContent || "{}")
  }
}

export default ClientData
